import React, {FC, ReactNode} from 'react'
import * as Styles from './index.module.scss'

interface Props {
    onRef?: string | ((instance: HTMLDivElement | null) => void) | React.RefObject<HTMLDivElement> | null
    children: ReactNode
}

const Wrapper:FC<Props> = ({children, onRef}:Props) => {
    return <div className={`${Styles.wrapper}`} ref={onRef}>{children}</div>
}

export default Wrapper