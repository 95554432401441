import * as React from 'react'
import * as Styles from './index.module.scss'

interface Props {
    className?: string
    children: React.ReactNode
}

const Section:React.FunctionComponent<Props> = (props:Props) => {
    const className = `${Styles.section} ${props.className ? props.className : ''}`.trim()
    return <section className={className}>{props.children}</section>
}

export default Section