import React, { FC, useState, useEffect } from 'react'
import Header from '../../components/header'
import Wrapper from '../../components/wrapper'
import Box from '../../components/box'
import { TitleH1Outline, Body2 } from '../../components/text'
import useViewport from '../../hooks/use-viewport'
import Section from '../../components/section'
import Store1 from '../../resources/images/store-1.png'
import Store2 from '../../resources/images/store-2.png'
import Store3 from '../../resources/images/store-3.png'
import Store4 from '../../resources/images/store-4.png'
import Store5 from '../../resources/images/store-5.png'
import Store6 from '../../resources/images/store-6.png'
import Store7 from '../../resources/images/store-7.png'
import Store8 from '../../resources/images/store-8.png'
import styled from 'styled-components'
import Helmet from 'react-helmet'

const HeadLine = styled(TitleH1Outline)`
font-size: 50px;
margin:bottom

@media(min-width: 768px) {
    font-size: 70px;
}
@media(min-width: 992px) {
    font-size: 100px;
}
`

const AnimatedDiv = styled.div<{animated:boolean}>`
position: relative;
opacity: 0;
top: 100px;
transition: all 1s;
width: 100%;
height: auto;
${attrs => attrs.animated && `opacity: 1; top: 0;`}
`

const OurStoreContainer:FC = () => {

    const gutter = useViewport().resolve({sm: '5px', md: '12px'})
    const width = `calc(50% - ${gutter})`;
    const margin = `${gutter} 0`;
    const [animated, setAnimated] = useState<{h1: boolean}>({h1: false});

    useEffect(() => {
        setAnimated({h1: true})
    }, [])

    return <>
        <Helmet>
            <title>Our Store - Reme Oggipets</title>
        </Helmet>
        <Header>
            <Wrapper>
                <Box full={true} maxWidth={760}>
                    <HeadLine animated={animated.h1}>Our store</HeadLine>
                </Box>
            </Wrapper>
        </Header>
        <Section>
        <AnimatedDiv animated={animated.h1}>
            <Wrapper>
                <Box direction={'column'} maxWidth={760} margin={'x8Bottom'} padding={'x8Bottom'}>
                    <Box full={true}>
                        <img style={{margin}} src={Store1}/>
                    </Box>
                    <Box full={true} alignItems={'flexStart'} justifyContent={'spaceBetween'}>
                        <img style={{margin, width}} src={Store2}/>
                        <img style={{margin, width}} src={Store3}/>
                    </Box>
                    <Box full={true}>
                        <img style={{margin}} src={Store4}/>
                    </Box>
                    <Box full={true} alignItems={'flexStart'} justifyContent={'spaceBetween'}>
                        <img style={{margin, width}} src={Store5}/>
                        <img style={{margin, width}} src={Store6}/>
                    </Box>
                    <Box full={true} alignItems={'flexStart'} justifyContent={'spaceBetween'}>
                        <img style={{margin, width}} src={Store7}/>
                        <img style={{margin, width}} src={Store8}/>
                    </Box>
                </Box>
            </Wrapper>
        </AnimatedDiv>
    </Section>
    </>
}

export default OurStoreContainer