import * as React from 'react'
import * as Styles from './index.module.scss'

interface Props {
    className?: string
    items: string[]
}

const List:React.FC<Props> = (props:Props) => {

    const {items, className = ''} = props

    const listClassName = `${Styles.list} ${props.className}`.trim()

    return <ul className={listClassName}>
        {items.map((item, i) => <li key={i} className={Styles.item}>
            <i className={Styles.bulletPoint}>
                <i className={Styles.bulletPointIcon}></i>
            </i>
            <span className={Styles.text}>{item}</span>
        </li>)}
    </ul>
}

export default List