import React from 'react'
import Logo from '../../resources/images/reme-logo.svg'

interface Props {
    maxWidth?: number | string
}

export default function Brand({maxWidth}:Props){

    return <img src={Logo} style={{maxWidth, width: '100%'}}/>
}