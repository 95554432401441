import React, { useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Link, useHistory } from 'react-router-dom'
import styled from 'styled-components'
import Box from '../../components/box'
import Brand from '../../components/brand'
import CoverImage from '../../components/cover-image'
import Header from '../../components/header'
import HighlightLine from '../../components/highlight-line'
import ImageFrame from '../../components/image-frame'
import PuppyImage from '../../components/puppy-image'
import Section from '../../components/section'
import ShapeImage from '../../components/shape-image'
import { Body2, TitleH1Filled, TitleH2 } from '../../components/text'
import Wrapper from '../../components/wrapper'
import useScroll from '../../hooks/use-scroll'
import useViewport from '../../hooks/use-viewport'
import BoardingIcon from '../../resources/images/boarding-icon.png'
import DaycareIcon from '../../resources/images/daycare-icon.png'
import Doggy1 from '../../resources/images/doggie-group-hi-res-cropped.png'
import Cover from '../../resources/images/front-page-dog.png'
import GroomingIcon from '../../resources/images/grooming-icon.png'
import CoverSM from '../../resources/images/home-cover-sm.png'

const ShapeImage1 = styled(ShapeImage)`
left: -200px;
top: -40px;
@media(min-width: 768px) {
    top: 70px;
}

${attrs => attrs.animated && `left: -100px`}
`

const PuppyImage1 = styled(PuppyImage)`
    left: -100px;
    ${attrs => attrs.animated && `left: 0px;`}
`

const HomeContainer: React.FunctionComponent = () => {

    const viewport = useViewport()
    const history = useHistory()

    const scrollY = useScroll().y;

    const [animated, setAnimated] = useState<{ h1?: boolean, trigger1?: boolean, trigger2?: boolean }>({
        h1: false,
        trigger1: false,
        trigger2: false
    });
    const trigger1Ref = useRef<HTMLDivElement>(null)
    const trigger2Ref = useRef<HTMLDivElement>(null)

    function onClickLink(to: string) {

        return function (e: React.MouseEvent<HTMLAnchorElement>) {
            history.push(to)
            window.scrollTo(0, 0)
            e.preventDefault()
        }
    }

    useEffect(() => {

        function getElementY(element: HTMLDivElement) {
            return element.getBoundingClientRect().y
        }

        if (trigger1Ref.current) {
            if (scrollY > getElementY(trigger1Ref.current)) {
                setAnimated(a => ({ ...a, trigger1: true }))
            } else {
                setAnimated(a => ({ ...a, trigger1: false }))
            }
        }
        if (trigger2Ref.current) {
            if (scrollY - trigger2Ref.current.clientHeight > getElementY(trigger2Ref.current)) {
                setAnimated(a => ({ ...a, trigger2: true }))
            } else {
                setAnimated(a => ({ ...a, trigger2: false }))
            }
        }
    }, [scrollY])

    useEffect(() => setAnimated(a => ({ ...a, h1: true })), [])

    return <>
        <Helmet>
            <title>Reme Oggipets</title>
        </Helmet>
        <Header>
            <Wrapper>
                <Box full={true} direction={viewport.resolve({ md: 'row', sm: 'columnReverse' })}>
                    <Box justifyContent={'center'} direction={'column'} full={true} margin={viewport.resolve({ sm: 'x4Top', md: 'none' })}  >
                        <Brand maxWidth={viewport.resolve({ md: 334, sm: 220 })} />
                        <TitleH1Filled animated={animated.h1}>”Where play time <br />is the best time <br />of the day”</TitleH1Filled>
                    </Box>
                    <Box full={true} justifyContent={viewport.resolve({ md: 'flexEnd', sm: 'center' })}>
                        <CoverImage animated={animated.h1} src={viewport.resolve({ sm: CoverSM, md: Cover })} />
                    </Box>
                </Box>
            </Wrapper>
        </Header>
        <Section>
            <Wrapper>
                <Box full={true} direction={viewport.resolve({ md: 'row', sm: 'column' })} padding={'x8Vertical'}>
                    <Box maxWidth={viewport.resolve({ sm: 'none', md: '50%' })}
                        full={true}
                        padding={viewport.resolve({ sm: 'x1Horizontal', md: 'x8Right' })}
                        justifyContent={viewport.resolve({ sm: 'center', md: 'flexEnd' })}>
                        <ImageFrame ref={trigger1Ref}>
                            <PuppyImage1 src={Doggy1} animated={animated.trigger1} />
                            <ShapeImage1 animated={animated.trigger1} />
                        </ImageFrame>
                    </Box>
                    <Box maxWidth={viewport.resolve({ sm: 'none', md: '50%' })}
                        margin={viewport.resolve({ md: 'none', sm: 'x8Top' })}
                        full={true}
                        padding={'x1Horizontal'}
                        direction={'column'}
                        alignItems={'flexStart'}
                        justifyContent={'center'}>
                        <Brand maxWidth={149} />
                        <Body2 margin={'x4Top'}>Located at 1001 First Avenue in New York City, we have been providing dog services to our clients since 2002. Our Daycare is one of the most modern facilities on the East Side of Manhattan, creating an ideal and safe space “Where play time is the best time of the day”.</Body2>
                    </Box>
                </Box>
            </Wrapper>
        </Section>
        <Section>
            <Wrapper>
                <Box direction={'column'} alignItems={'flexStart'} padding={'x1Horizontal'} full={true} margin={'x8Vertical'}>
                    <HighlightLine>
                        <TitleH2>Our Services</TitleH2>
                    </HighlightLine>
                </Box>
                <Box full={true}
                    direction={viewport.resolve({ md: 'row', sm: 'column' })}
                    padding={'x1Horizontal'}
                    justifyContent={'flexStart'}
                    margin={'x8Bottom'}>
                    <Box direction={'column'} margin={'x3Bottom'}>
                        <Link to={'#'} onClick={onClickLink('/daycare')}>
                            <img src={DaycareIcon} />
                        </Link>
                        <Body2 margin={'x3Top'}>Daycare</Body2>
                    </Box>
                    <Box direction={'column'} margin={'x3Bottom'} padding={viewport.resolve({ md: 'x2Horizontal', sm: 'none' })}>
                        <Link to={'#'} onClick={onClickLink('/grooming')}>
                            <img src={GroomingIcon} />
                        </Link>
                        <Body2 margin={'x3Top'}>Grooming</Body2>
                    </Box>
                </Box>
            </Wrapper>
        </Section>
    </>
}

export default HomeContainer