import * as React from 'react'
import ResolutionContext from '../../context/resolution-context'

type Breakpoint = 'sm' | 'md'

interface BreakpointObject<T> {
    sm: T,
    md: T,
}

export default function useViewport(){

    const {width} = React.useContext(ResolutionContext)

    const breakpoint:Breakpoint = width > 767  ? 'md' : 'sm'

    function resolve<T>(breakpointObject:BreakpointObject<T>){
        return breakpointObject[breakpoint]
    }

    return { currentBreakpoint: breakpoint, resolve }
}