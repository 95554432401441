import * as React from 'react'
import * as Styles from './index.module.scss'

interface Props {
    children: React.ReactNode
    background?: 'white' | 'gray'
}

const Header:React.FunctionComponent<Props> = (props:Props) => {
    const {background = 'white'} = props
    return <header className={`${Styles.header} ${Styles[background]}`}>{props.children}</header>
}

export default Header