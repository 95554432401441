import * as React from 'react'
import * as Styles from './index.module.scss'
import * as JustifyContent from './justify-content.module.scss'
import * as AlignItems from './align-items.module.scss'
import * as FlexDirection from './flex-direction.module.scss'
import useBlock from '../../hooks/use-block'
import Sizes from '../../models/sizes'
import Styled from 'styled-components'

type flexPropertiesValues =
| "center"
| "end"
| "flexEnd"
| "flexStart"
| "start"
| "left"
| "normal"
| "right"
| "spaceBetween"
| "spaceAround"
| "stretch";

type flexDirectionPropertiesValues =
| "column"
| "row"
| "columnReverse"
| "rowReverse";

interface Props {
    className?: string
    justifyContent?: flexPropertiesValues
    alignItems?: flexPropertiesValues
    direction?: flexDirectionPropertiesValues,
    margin?: Sizes,
    padding?: Sizes
    full?: boolean,
    maxWidth?: number | string,
    minHeight?: number | string,
    children: React.ReactNode,
}

function formatSize(size?: number | string){
    return typeof size === 'string' ? size : `${size}px`
}

interface FlexBoxDivProps {
    maxWidth?: number | string,
    minHeight?: number | string,
}

const FlexBoxDiv = Styled.div<FlexBoxDivProps>`
        ${attrs => attrs.maxWidth && `max-width: ${formatSize(attrs.maxWidth)};`}
        ${attrs => attrs.minHeight && `max-height: ${formatSize(attrs.minHeight)};`}
    `

const Box:React.FC<Props> = (props:Props) => {

    const { margin, padding, alignItems, justifyContent, direction, maxWidth, minHeight} = props

    const block = useBlock()

    const className = props.className ? ` ${props.className}` : '';

    const justifyContentClassName = JustifyContent[justifyContent || 'flexStart'];

    const alignItemsClassName = AlignItems[alignItems || 'center'];

    const directionClassName = FlexDirection[direction || 'row'];

    const marginClassName = !!margin ? ` ${block.margin(margin)}` : '';

    const paddingClassName = !!padding ? ` ${block.padding(padding)}` : '';

    // const borderClassName = !!props.border ? ` ${Block.getBorderClassName(props.border)}` : '';

    // const backgroundClassName = !!props.background ? ` ${Colors.getBackgroundColorClassName(props.background)}` : '';

    const fullClassName = !!props.full ? ` ${Styles.full}` : ''

    return <FlexBoxDiv
        {...{maxWidth, minHeight}}
        className={`${Styles.box} ${justifyContentClassName} ${alignItemsClassName} ${directionClassName}${marginClassName}${paddingClassName}${className}${fullClassName}`.trim()}>
            {props.children}
        </FlexBoxDiv>
}

export default Box