import styled from 'styled-components'

interface Props {
    animated?: boolean
}

const PuppyImage = styled.img<Props>`
    position: relative;
    z-index: 1;
    transition: all 0.3s;
`

export default PuppyImage