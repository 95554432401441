import React, { FC, useEffect, useRef, useState } from 'react'
import Helmet from 'react-helmet'
import styled from 'styled-components'
import Box from '../../components/box'
import CoverImage from '../../components/cover-image'
import Header from '../../components/header'
import HighlightLine from '../../components/highlight-line'
import ImageFrame from '../../components/image-frame'
import List from '../../components/list'
import PriceTable from '../../components/price-table'
import PuppyImage from '../../components/puppy-image'
import Section from '../../components/section'
import ShapeImage from '../../components/shape-image'
import { Body2, TitleH1Outline, TitleH2 } from '../../components/text'
import Wrapper from '../../components/wrapper'
import useScroll from '../../hooks/use-scroll'
import useViewport from '../../hooks/use-viewport'
import ChiguaguasFighting from '../../resources/images/chiguguas-fighting.png'
import Cover from '../../resources/images/daycare-cover.png'
import DaycareGroup from '../../resources/images/daycare-group.png'
import OliverStairs from '../../resources/images/oliver-stairs.png'

const HeadLine = styled(TitleH1Outline)`
font-size: 50px;
@media(min-width: 768px) {
    font-size: 70px;
}
@media(min-width: 992px) {
    font-size: 100px;
}
`

const ShapeImage1 = styled(ShapeImage)`
left: -200px;
top: -40px;
@media(min-width: 768px) {
    top: 70px;
}

${attrs => attrs.animated && `left: -100px`}
`

const ShapeImage2 = styled(ShapeImage)`
top: 50px;
left: -100px;
${attrs => attrs.animated && `left: 0`}
`

const PuppyImage1 = styled(PuppyImage)`
    left: -100px;
    ${attrs => attrs.animated && `left: 0px;`}
`

const PuppyImage2 = styled(PuppyImage)`
    right: -100px;
    ${attrs => attrs.animated && `right: -20px`}
`

const DaycareContainer: FC = () => {

    const viewport = useViewport()
    const scrollY = useScroll().y;

    const [animated, setAnimated] = useState<{ h1?: boolean, trigger1?: boolean, trigger2?: boolean }>({
        h1: false,
        trigger1: false,
        trigger2: false
    });
    const trigger1Ref = useRef<HTMLDivElement>(null)
    const trigger2Ref = useRef<HTMLDivElement>(null)

    useEffect(() => {

        function getElementY(element: HTMLDivElement) {
            return element.getBoundingClientRect().y
        }

        if (trigger1Ref.current) {
            if (scrollY > getElementY(trigger1Ref.current)) {
                setAnimated(a => ({ ...a, trigger1: true }))
            } else {
                setAnimated(a => ({ ...a, trigger1: false }))
            }
        }
        if (trigger2Ref.current) {
            if (scrollY - trigger2Ref.current.clientHeight > getElementY(trigger2Ref.current)) {
                setAnimated(a => ({ ...a, trigger2: true }))
            } else {
                setAnimated(a => ({ ...a, trigger2: false }))
            }
        }
    }, [scrollY])

    useEffect(() => setAnimated(a => ({ ...a, h1: true })), [])

    return <>
        <Helmet>
            <title>Daycare - Reme Oggipets</title>
        </Helmet>
        <Header>
            <Wrapper>
                <Box full={true} direction={viewport.resolve({ sm: 'columnReverse', md: 'row' })}>
                    <Box full={true} maxWidth={viewport.resolve({ sm: 'auto', md: '50%' })} direction={'column'} alignItems={'flexStart'} justifyContent={'center'}>
                        <HeadLine animated={animated.h1}>Daycare</HeadLine>
                        <HighlightLine position={'top'}>
                            <Body2>Our daycare is a service designed for <br /> social dogs to play and have fun.</Body2>
                        </HighlightLine>
                    </Box>
                    <Box full={true} maxWidth={viewport.resolve({ sm: 'auto', md: '50%' })}>
                        <CoverImage animated={animated.h1} src={Cover} />
                    </Box>
                </Box>
            </Wrapper>
        </Header>
        <Section>
            <Wrapper>
                <Box full={true} margin={'x3Vertical'} maxWidth={760}>
                    <Body2>At Reme’s Oggi Pets we offer a safe environment for socialization and play in play groups to maximize the fun and quality of play. Dogs enjoy interacting and forming special friendships. Socialization also builds confidence in many dogs who are timid or fearful.
In our playrooms all dogs are supervised by one of our staff to promote good manners and behaviors.</Body2>
                </Box>
            </Wrapper>
        </Section>
        <Section>
            <Wrapper>
                <Box full={true} direction={viewport.resolve({ sm: 'column', md: 'row' })} margin={'x8Vertical'}>
                    <Box maxWidth={viewport.resolve({ sm: 'none', md: '50%' })}
                        full={true}
                        padding={'x1Horizontal'}
                        justifyContent={viewport.resolve({ sm: 'center', md: 'flexEnd' })}>
                        <ImageFrame ref={trigger1Ref}>
                            <PuppyImage1 src={ChiguaguasFighting} animated={animated.trigger1} />
                            <ShapeImage1 animated={animated.trigger1} />
                        </ImageFrame>
                    </Box>
                    <Box direction={'column'} justifyContent={'flexStart'} alignItems={'flexStart'} padding={viewport.resolve({ sm: 'none', md: 'x2Left' })}>

                        <HighlightLine>
                            <TitleH2>All dogs attending Daycare are required to:</TitleH2>
                        </HighlightLine>
                        <Box full={true}>
                            <List items={[
                                'Be 20lbs or under',
                                'Be at least four months old',
                                'Be in general good health and have written proof of up-to-date vaccinations including rabies, DHLPP and Bordatella.',
                                'Be spayed or neutered – puppies under six months of age are exempt',
                                'All dogs must have a trial day',
                                'All dogs must be registered'
                            ]} />
                        </Box>
                    </Box>
                </Box>
            </Wrapper>
        </Section>
        <Section>
            <Wrapper>
                <Box full={true} alignItems={'flexStart'} padding={viewport.resolve({ sm: 'none', md: 'x3Horizontal' })} direction={'column'} maxWidth={760} margin={'x8Vertical'}>
                    <HighlightLine>
                        <TitleH2>Daycare Rates</TitleH2>
                    </HighlightLine>
                    <Body2>We offer a free membership to all our clients. Our Packages expire 365 days after purchase.</Body2>
                    <PriceTable items={[
                        {
                            name: 'Monthly Package*',
                            price: ''
                        },
                        {
                            name: '10 Day Package',
                            price: ''
                        },
                        {
                            name: '20 Day Package ',
                            price: ''
                        },
                        {
                            name: '5 Half Day Package**',
                            price: ''
                        },
                        {
                            name: '10 Half Day Package**',
                            price: ''
                        },
                        {
                            name: 'Single Daycare Visit',
                            price: ''
                        },
                        {
                            name: 'Single Half Daycare Visit',
                            price: ''
                        },
                        {
                            name: 'Trial Day Visit ( 2 hours )',
                            price: ''
                        },
                    ]}
                    />
                    <Body2 margin={'x8Top'}>For all Daycare Packages - no refund and no credit back</Body2>
                    <Body2 margin={'x1Top'}>Extended Half Day is an additional</Body2>
                    <Body2 margin={'x1Top'}>* Monthly packages are valid from the first of the month to the first of the following month - there are no extensions</Body2>
                    
                </Box>
            </Wrapper>
        </Section>
        <Section>
            <Wrapper>
                <Box direction={viewport.resolve({ sm: 'columnReverse', md: 'row' })} margin={'x8Bottom'}>
                    <Box direction={'column'}
                        alignItems={'flexStart'}
                        justifyContent={'stretch'}
                        maxWidth={viewport.resolve({ sm: 'none', md: '50%' })} >
                        <HighlightLine>
                            <TitleH2>Relief Walks for daycare</TitleH2>
                        </HighlightLine>
                        <Body2 margin={'x4Bottom'}>One-on-One Relief Walks (5-10 minutes) are $10 per walk or a relief walk package at $5 per walk when you buy in combination with a daycare package.</Body2>
                        <Body2 margin={'x4Bottom'}>We reserve the right to refuse service</Body2>
                        <img src={DaycareGroup} width={'100%'} />
                    </Box>
                    <Box maxWidth={viewport.resolve({ sm: 'none', md: '50%' })}
                        full={true}
                        justifyContent={viewport.resolve({ sm: 'center', md: 'flexEnd' })}>
                        <ImageFrame ref={trigger2Ref}>
                            <PuppyImage2 src={OliverStairs} animated={animated.trigger2} />
                            <ShapeImage2 animated={animated.trigger2} />
                        </ImageFrame>
                    </Box>
                </Box>
            </Wrapper>
        </Section>
    </>
}

export default DaycareContainer