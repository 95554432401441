import * as React from 'react'
import * as Styles from './index.module.scss'
import Wrapper from '../wrapper'
import { Link, useHistory } from 'react-router-dom'
import Logo from '../../resources/images/reme-logo.svg'
import Tel from '../../resources/images/tel.svg'
import Fb from '../../resources/images/fb.svg'
import Mail from '../../resources/images/mail.svg'
import Ins from '../../resources/images/instagram.svg'
import * as Text from '../text'
import Box from '../box'
import useViewport from '../../hooks/use-viewport'
import styled from 'styled-components'


const LinkP = styled.p`margin: 0 0 8px;`

const Footer:React.FC = () => {

    const viewport = useViewport()
    const history = useHistory()

    function onClickLink(to: string){
        
        return function(e:React.MouseEvent<HTMLAnchorElement>){
            history.push(to)
            window.scrollTo(0,0)
            e.preventDefault()
        }
    }

    return <footer className={Styles.footer}>
        <Wrapper>
            <Box alignItems={'flexStart'} direction={viewport.resolve(({sm: 'column', md: 'row'}))} full={true} padding={'x2Horizontal'}>
                <Box full={true} maxWidth={viewport.resolve(({sm: '100%', md: '25%'}))}>
                    <div className={Styles.column}>
                        <Link to={'/'}><img src={Logo} width={149} /></Link>
                    </div>
                </Box>
                <Box alignItems={'flexStart'} direction={'row'} full={true} maxWidth={viewport.resolve(({sm: '100%', md: '50%'}))}>
                    <Box direction={'column'} margin={'x2Right'} alignItems={'flexStart'} full={true} maxWidth={'50%'}>
                        <p className={Styles.title}>Services</p>
                        <LinkP><Link className={Styles.link} to={'#'} onClick={onClickLink('/daycare')}>Daycare</Link></LinkP>
                        <LinkP><Link className={Styles.link} to={'#'} onClick={onClickLink('/grooming')}>Grooming</Link></LinkP>
                        <LinkP><Link className={Styles.link} to={'#'} onClick={onClickLink('/our-store')}>Our Store</Link></LinkP>
                        <LinkP><Link className={Styles.link} to={'#'} onClick={onClickLink('/contact-us')}>Contact us</Link></LinkP>
                    </Box>
                    <Box alignItems={'flexStart'} full={true} margin={'x2Left'} maxWidth={'50%'}>
                        <address className={Styles.column}>
                            <p className={Styles.title}>Contact us</p>
                            <Box margin={'x3Vertical'}>
                                <a href={'tel:2128382250'} title={'Tel'}><img src={Tel} style={{marginRight: 12}} alt={'Tel'} /></a>
                                <a href={'https://www.facebook.com/remes.oggipets'} title={'Facebook'} target={'_blank'}><img src={Fb} style={{marginRight: 12}} alt={'Facebook'} /></a>
                                <a href={'mailto:remesoggipets@gmail.com'} title={'Email'}><img src={Mail} style={{marginRight: 12}}  alt={'Email'} /></a>
                                <a href={'https://www.instagram.com/remesoggipets/'} target={'_blank'} title={'Instagram'}><img src={Ins} alt={'Instagram'} /></a>
                            </Box>
                            <Text.Body3>1001 First Avenue<br/> at the corner of First Avenue and 55th Street</Text.Body3>
                        </address>
                    </Box>
                </Box>
            </Box>
        </Wrapper>
        <Wrapper>
            <div className={Styles.subfooter}><p className={Styles.terms}>©2020 remes oggi pets</p></div>
        </Wrapper>
    </footer>
}

export default Footer