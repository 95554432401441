import React from 'react'
import styled from 'styled-components'
import FunnyShape from '../../resources/images/funny-shape.svg'

interface Props {
    animated?: boolean
}

const Shape = styled.img<Props>`
    position: absolute;
    z-index: 0;
    transition: all 0.5s;
    opacity: 0;
    ${attr => attr.animated && `opacity: 1;`}
`

const ShapeImage = (props:Props) => <Shape src={FunnyShape} {...props} /> 

export default ShapeImage