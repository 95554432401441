import React, { FC, useRef } from 'react'
import styled from 'styled-components'

interface Props {
    animated?: boolean;
    src: string
}

interface FrameProps extends Pick<Props, 'animated'> {
    minHeight?: number
}

const Img = styled.img<Props>`
    width: 100%;
`

const Frame = styled.div<FrameProps>`
    width: 100%;
    height: auto;
    transition: opacity 0.5s 0.5s;
    opacity: 0;
    ${attrs => `min-height: ${attrs.minHeight ? attrs.minHeight : 2}px;`}
    ${attrs => attrs.animated ? `opacity: 1;` : ''}
`

const CoverImage:FC<Props> = ({src, animated}) => {

    const imgRef = useRef<HTMLImageElement>(null)

    return <Frame animated={animated} minHeight={imgRef.current?.clientHeight}>
        <Img src={src} ref={imgRef} />
    </Frame>
}



export default CoverImage