import { createContext } from "react";

interface Props {
    width: number
    height: number
}

const ResolutionContext = createContext<Props>({
    width: 0,
    height: 0 
})

export default ResolutionContext