import styled from 'styled-components'

const ImageFrame = styled.div`
    position: relative;
    width: auto;
    height: auto;
`



export default ImageFrame