import { createContext } from "react";

interface Props {
    x: number
    y: number
}

const ScrollContext = createContext<Props>({
    x: 0,
    y: 0 
})

export default ScrollContext