import * as React from 'react'
import * as Styles from './index.module.scss'

interface Props {
    startAnimation?: boolean,
    lineLeft?: number | string
    lineWidth?: number | string
    position?: 'top' | 'bottom'
}


const HighlightLine:React.FunctionComponent<Props> = (props) => {
    const {startAnimation, lineLeft, lineWidth, position, children} = props

    const className = `${Styles.line} ${startAnimation ? Styles.animation : ''}`

    const isPositionTop = position === 'top'

    return <div className={Styles.highlightLine}>
        {isPositionTop &&  <hr className={className} />}
        {children}
        {!isPositionTop &&  <hr className={className} />}
    </div>
}

export default HighlightLine