import * as React from 'react'
import * as Styles from './index.module.scss'
import Sizes from '../../models/sizes'
import useBlock from '../../hooks/use-block'
import styled from 'styled-components'

interface Props {
    margin?: Sizes
    children: React.ReactNode
    className?: string
}

export const TitleH1Outline = styled.h1<{animated?: boolean}>`
    position: relative;
    font-family: sans-serif;
    font-style: normal;
    font-weight: 900;
    color: transparent;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #333333;
    margin-top: 0;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 0;
    transition: all 0.3s;
    left: -100px;
    opacity: 0;
    ${attrs => attrs.animated && `
        left: 0;
        opacity: 1;
    `};
`

export const TitleH1Filled = styled.h1<{animated?: boolean}>`
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 130%;
    letter-spacing: 0.04em;
    color: #000000;
    text-align: center;
    transition: all 0.3s;
    margin-top: 60px;

    @media(min-width: 350px) {
        font-size: 32px;
    }

    @media(min-width: 768px) {
        font-size: 42px;
        margin-top: 30px;
    }

    left: -100px;
    opacity: 0;
    ${attrs => attrs.animated && `
        left: 0;
        opacity: 1;
    `};
`

export const TitleH2:React.FC<Props> = (props:Props) => {
    const {className = '', margin = 'none'} = props
    const block = useBlock()
    const marginClassName = block.margin(margin)
    return <h2 className={`${Styles.text} ${Styles.titleH2} ${className} ${marginClassName}`.trim()}>{props.children}</h2>
}

export const Body1:React.FC<Props> = (props:Props) => {
    const {className = '', margin = 'none'} = props
    const block = useBlock()
    const marginClassName = block.margin(margin)
    return <p className={`${Styles.text} ${Styles.body} ${Styles.body1} ${className} ${marginClassName}`.trim()}>{props.children}</p>
}

export const Body2:React.FC<Props> = (props:Props) => {
    const {className = '', margin = 'none'} = props
    const block = useBlock()
    const marginClassName = block.margin(margin)
    return <p className={`${Styles.text} ${Styles.body} ${Styles.body2} ${className} ${marginClassName}`.trim()}>{props.children}</p>
}

export const Body3:React.FC<Props> = (props:Props) => {
    const {className = '', margin = 'none'} = props
    const block = useBlock()
    const marginClassName = block.margin(margin)
    return <p className={`${Styles.text} ${Styles.body} ${Styles.body3} ${className} ${marginClassName}`.trim()}>{props.children}</p>
}


export const Link:React.FC<Props & {href: string}> = (props:Props & {href: string}) => {
    const {href} = props
    
    return <p><a href={href} className={Styles.link}>{props.children}</a></p>
}