import * as React from 'react'
import * as Text from '../text'
import * as Styles from './index.module.scss'

interface Props {
    items: {
        name: string,
        price: string
    }[]
}

const PriceTable:React.FC<Props> = (props:Props) => {

    const {items} = props

    return <table className={Styles.priceTable}>
        <tbody>
            {
                items.map((item, i) => <tr key={i}>
                    <td className={Styles.name}><Text.Body2>{item.name}</Text.Body2></td>
                    <td className={Styles.price}><Text.Body2>{item.price}</Text.Body2></td>
                </tr>)
            }
        </tbody>
        
    </table>
}

export default PriceTable