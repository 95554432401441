import * as React from 'react'
import * as Styles from './index.module.scss'
import Wrapper from '../wrapper'
import Logo from '../../resources/images/reme-logo.svg'
import { Link } from 'react-router-dom'
import Box from '../box'

interface Props {
    path: string
}

const Navbar:React.FunctionComponent<Props> = ({path}:Props) => {

    const [isOpen, setIsOpen] = React.useState<boolean>(false)

    function onClick(){
        window.scrollTo(0,0)
        setIsOpen(false)
    }

    return <div className={Styles.navbarWrapper}>
        <nav className={Styles.navbar}>
            <Wrapper >
                <Box justifyContent={'spaceBetween'} full={true}>
                    <Link onClick={onClick} to={'/'}><img src={Logo} width={149} /></Link>
                    <ul className={`${Styles.menu}${isOpen ? ` ${Styles.open}` : ''}`}>
                        <li className={Styles.item}><Link onClick={onClick} className={path === '/daycare' ? Styles.current : ''} to="/daycare">Daycare</Link></li>
                        <li className={Styles.item}><Link onClick={onClick} className={path === '/grooming' ? Styles.current : ''} to="/grooming">Grooming</Link></li>
                        <li className={Styles.item}><Link onClick={onClick} className={path === '/our-store' ? Styles.current : ''} to="/our-store">Our Store</Link></li>
                        <li className={Styles.item}><Link onClick={onClick} className={path === '/contact-us' ? Styles.current : ''} to="/contact-us">Contact us</Link></li>
                    </ul>
                    <button className={Styles.menuToggle} onClick={(e) => {
                        setIsOpen(!isOpen)
                        e.preventDefault()
                    }}>
                        <i className="material-icons">{isOpen ? 'close' : 'menu'}</i>
                    </button>
                </Box>
            </Wrapper>
        </nav>
    </div>
}

export default Navbar