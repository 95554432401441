import * as React from 'react'
import * as Styles from './index.module.scss'
import Navbar from '../components/navbar'
import Footer from '../components/footer'
import { RouteComponentProps } from 'react-router-dom'

interface Props extends RouteComponentProps {
    children: React.ReactNode
}

const Master:React.FunctionComponent<Props> = (props:Props) => {

    const [path, setPath] = React.useState<string>('')

    React.useEffect(() => {

        function calculatePath(){
            return props.history.location.pathname
        }

        setPath(calculatePath())

        props.history.listen(() => {
            setPath(calculatePath())
        })
    }, [])

    return <div className={Styles.master}>
        <Navbar path={path} />
        {props.children}
        <Footer/>
    </div>
}

export default Master