import * as Margin from './margin.module.scss'
import * as Padding from './padding.module.scss'
import useViewport from '../use-viewport'
import Sizes from '../../models/sizes'

interface ResponsiveSizes {
    xs: Sizes,
    sm: Sizes,
    md: Sizes,
    lg: Sizes
}

export default function useBlock(){

    const viewport = useViewport()

    function getClassName(type: 'margin' | 'padding'){

        return function(size:Sizes | ResponsiveSizes):string{

            const className:Sizes = typeof size === 'string' ? size : viewport.resolve(size)

            return type === 'margin' ? Margin[className] : Padding[className]   
        }
    }

    const margin = getClassName('margin')
    const padding = getClassName('padding')

    return { margin, padding }
}