import React, { FC, useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet'
import styled from 'styled-components'
import Box from '../../components/box'
import CoverImage from '../../components/cover-image'
import Header from '../../components/header'
import HighlightLine from '../../components/highlight-line'
import ImageFrame from '../../components/image-frame'
import PuppyImage from '../../components/puppy-image'
import Section from '../../components/section'
import ShapeImage from '../../components/shape-image'
import { Body2, TitleH1Outline, TitleH2 } from '../../components/text'
import Wrapper from '../../components/wrapper'
import useScroll from '../../hooks/use-scroll'
import useViewport from '../../hooks/use-viewport'
import Cover from '../../resources/images/cover-grooming.png'
import GroomingBath from '../../resources/images/grooming-bath.png'
import WhiteDog from '../../resources/images/white-dog.png'

const HeadLine = styled(TitleH1Outline)`
        font-size: 50px;
        
        @media(min-width: 768px) {
            font-size: 70px;
        }
        @media(min-width: 992px) {
            font-size: 100px;
        }
    `

    const ShapeImage1 = styled(ShapeImage)`
        left: -200px;
        top: 35px;
        @media (min-width: 768px) {
            top: 100px;
        }
        ${attrs => attrs.animated && `left: -100px;`}
    `

    const ShapeImage2 = styled(ShapeImage)`
        top: 0;
        left: 50px;
        @media (min-width: 768px) {
            left: 100px;
        }
        ${attrs => attrs.animated && `left: 0px;`}
    `

    const PuppyImage1 = styled(PuppyImage)`
        left: -100px;
        ${attrs => attrs.animated && `left: 0px;`}
    `

    const PuppyImage2 = styled(PuppyImage)`
        top: 50px;
        ${attrs => attrs.animated && `top: 0px;`}
    `

    const ImageFrame1 = styled(ImageFrame)`
        @media (max-width: 767px) {
            height: 350px;
        }
    `

const GroomingContainer:FC = () => {

    const viewport = useViewport()

    const scrollY = useScroll().y;

    const [animated, setAnimated] = useState<{h1?: boolean, trigger1?: boolean, trigger2?: boolean}>({
        h1: false, 
        trigger1: false, 
        trigger2: false
    });
    const trigger1Ref = useRef<HTMLDivElement>(null)
    const trigger2Ref = useRef<HTMLDivElement>(null)

    useEffect(() => {

        function getElementY(element:HTMLDivElement){
            return element.getBoundingClientRect().y
        }

        if(trigger1Ref.current){
            if(scrollY > getElementY(trigger1Ref.current)){
                setAnimated(a => ({...a, ...{trigger1: true}}))
            }else{
                setAnimated(a => ({...a, ...{trigger1: false}}))
            }
        }
        if(trigger2Ref.current){
            if(scrollY - trigger2Ref.current.clientHeight/2 > getElementY(trigger2Ref.current)){
                setAnimated(a => ({...a, ...{trigger2: true}}))
            }else{
                setAnimated(a => ({...a, ...{trigger2: false}}))
            }
        }
    }, [scrollY])

    useEffect(() => setAnimated(a => ({...a, ...{h1: true}})), [])
    return <>
        <Helmet>
            <title>Grooming - Reme Oggipets</title>
        </Helmet>
        <Header>
            <Wrapper>
                <Box full={true} direction={viewport.resolve({md: 'row', sm: 'columnReverse'})}>
                    <Box justifyContent={'center'} alignItems={'flexStart'} direction={'column'} full={true}>
                        <HeadLine animated={animated.h1}>Grooming</HeadLine> 
                        <HighlightLine position={'top'}>
                            <Body2>At Reme’s Oggi Pets we are committed to <br/>providing top quality grooming.</Body2>
                        </HighlightLine>
                    </Box>
                    <Box>
                        <CoverImage src={Cover} animated={animated.h1} />
                    </Box>
                </Box>
            </Wrapper>
       </Header>
       <Section>
           <Wrapper>
               <Box full={true} margin={viewport.resolve({sm: 'x4Bottom', md:'x8Vertical'})} maxWidth={760}> 
                    <Body2>All our grooming services are available by appointment only, we recommend calling a few days
in advance for full grooming services. We can often accommodate same day appointments for
baths. New clients need to register when they arrive for their first appointment. Grooming prices
vary by breed and condition of the coat.</Body2>
                </Box>
           </Wrapper>
       </Section>
       <Section>
           <Wrapper>
                <Box full={true} 
                    margin={viewport.resolve({sm: 'none', md:'x8Vertical'})}
                    direction={viewport.resolve({sm: 'column', md: 'row'})}>
                    <Box maxWidth={viewport.resolve({sm: 'none', md: '50%'})} justifyContent={'flexEnd'} padding={viewport.resolve({sm: 'none', md: 'x3Right'})}  full={true} >
                        <ImageFrame1 ref={trigger1Ref}>
                            <PuppyImage1 src={GroomingBath} animated={animated.trigger1} />
                            <ShapeImage1 animated={animated.trigger1}/>
                        </ImageFrame1>
                    </Box>
                    <Box padding={viewport.resolve({sm: 'x4Vertical', md:'x4Left'})} maxWidth={viewport.resolve({sm: 'none', md: '50%'})} full={true} direction={'column'} justifyContent={'start'}  alignItems={'flexStart'}>
                        <HighlightLine>
                            <TitleH2>Bath / Cleanup</TitleH2>
                        </HighlightLine>
                        <Body2 margin={'x4Bottom'}>
                            This is a good healthy maintenance for your dog. It includes a full wash (with your requested shampoo or your vet prescription), hand drying, nail clipping and filing, ear cleaning and expression of the anal glands. All baths also include a trimming of the pads, a sanitary trim and trimming around the eyes.
                        </Body2>

                        <HighlightLine>
                            <TitleH2>Haircut</TitleH2>
                        </HighlightLine>
                        <Body2>This includes a bath / cleanup plus a trim at your request or the standard breed cut.</Body2>
                    </Box>
                </Box>
            </Wrapper>
        </Section>
        <Section>
           <Wrapper>
                <Box full={true} margin={'x8Vertical'} direction={viewport.resolve({sm: 'columnReverse', md: 'row'})}>
                    <Box maxWidth={viewport.resolve({sm: 'none', md: '50%'})} full={true}  direction={'column'} justifyContent={'start'} alignItems={'flexStart'}>
                            <HighlightLine>
                                <TitleH2>Teeth</TitleH2>
                            </HighlightLine>
                            <Body2 margin={'x4Bottom'}>Knowing the importance of a healthy mouth for your pet, we offer teeth brushing to our grooming clients for a cost of $10.00.</Body2>

                            <HighlightLine>
                                <TitleH2>Requirements</TitleH2>
                            </HighlightLine>
                        
                        <Body2>All dogs attending grooming are required to be in
        general good health, fit for grooming and have written proof of up to date vaccinations - including Rabies, DHLPP and Bordetella. These are the requirements of the Health Department of 
        New York City. <br/><br/><br/>We reserve the right to refuse service</Body2>
                    </Box>
                    <Box maxWidth={viewport.resolve({sm: 'none', md: '50%'})} full={true}  
                        alignItems={'flexStart'}
                        justifyContent={viewport.resolve({sm: 'center', md: 'flexStart'})}>
                            <ImageFrame ref={trigger2Ref}>
                                <PuppyImage2 src={WhiteDog} animated={animated.trigger2} />
                                <ShapeImage2 animated={animated.trigger2} />
                            </ImageFrame>
                    </Box>
               </Box>
           </Wrapper>
       </Section>
    </>
}

export default GroomingContainer