import * as React from 'react'
import Master from '../master'
import HomeContainer from '../containers/home'
import GroomingContainer from '../containers/grooming'
import { Switch, Route, BrowserRouter as Router, Redirect } from 'react-router-dom'
import DaycareContainer from '../containers/daycare'
import ContactUsContainer from '../containers/contact-us'
import {Helmet} from 'react-helmet'
import ResolutionContext from '../context/resolution-context';
import BoardingContainer from '../containers/boarding'
import OurStoreContainer from '../containers/our-store'
import ScrollContext from '../context'

const AppRouter:React.FunctionComponent = () => {

    const[currentResolution, setCurrentResolution] = React.useState<{width: number, height: number}>({
        width: window.innerWidth,
        height: window.innerHeight
    })

    const [scroll, setScroll] = React.useState<{x: number, y:number}>({x: 0, y: 0})

    React.useEffect(() => {

        function onResizeHandler(){
            setCurrentResolution({
                width: window.innerWidth,
                height: window.innerHeight
            })
        }

        function onScrollHandler(){
            setScroll({
                x: window.scrollX,
                y: window.scrollY
            })
        }

        window.addEventListener('resize', onResizeHandler)
        window.addEventListener('scroll', onScrollHandler)

        return () => {
            window.removeEventListener('resize', onResizeHandler)
            window.removeEventListener('scroll', onScrollHandler)
        }
    }, [])

    return <ResolutionContext.Provider value={currentResolution}>
            <ScrollContext.Provider value={scroll}>
                <Helmet>
                <title>Reme Oggipets</title>
            </Helmet>
            <Router>
                <Route render={(props) => <Master {...props}>
                    <Switch>
                        <Route exact path={'/'}>
                            <HomeContainer/>
                        </Route>
                        <Route exact path={'/daycare'}>
                            <DaycareContainer/>
                        </Route>
                        <Route exact path={'/grooming'}>
                            <GroomingContainer />
                        </Route>
                        <Route exact path={'/boarding'} >
                            <Redirect to={'/'}/>
                        </Route>
                        <Route exact path={'/our-store'}>
                            <OurStoreContainer/>
                        </Route>
                        <Route exact path={'/contact-us'}>
                            <ContactUsContainer/>
                        </Route>
                    </Switch>
                </Master>}/>
            </Router>
            </ScrollContext.Provider>
        </ResolutionContext.Provider>
    
}

export default AppRouter