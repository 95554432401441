import React, {FC, useState, useEffect} from 'react'
import Box from '../../components/box'
import Section from '../../components/section'
import {TitleH1Outline, Body2, Body3} from '../../components/text'
import Wrapper from '../../components/wrapper'
import Cover from '../../resources/images/contact-us-cover.png'
import Location from '../../resources/images/location.svg'
import Email from '../../resources/images/mail-address.svg'
import Phone from '../../resources/images/phone.svg'
import * as Styles from './index.module.scss'
import HighlightLine from '../../components/highlight-line'
import CoverImage from '../../components/cover-image'
import useViewport from '../../hooks/use-viewport'
import styled from 'styled-components'
import Helmet from 'react-helmet'

const HeadLine = styled(TitleH1Outline)`
        font-size: 42px;
        margin-bottom: 40px;
        margin: 0;
        
        @media(min-width: 768px) {
            margin-top: 40px;
            font-size: 70px;
        }
        @media(min-width: 992px) {
            font-size: 70px;
        }
    `
const ContactLink = styled.a`
        text-decoration: none;
        color: inherit; 
`

const ContactUsContainer:FC = () => {

    const viewport = useViewport()

    const [animated, setAnimated] = useState<{h1: boolean}>({h1: false});

    useEffect(() => {
        setAnimated({h1: true})
    }, [])

    return <>
        <Helmet>
            <title>Contact us - Reme Oggipets</title>
        </Helmet>
        <Section className={Styles.section}>
            <Wrapper >
                <Box full={true} margin={'x4Bottom'} justifyContent={'flexStart'} alignItems={'flexStart'} direction={viewport.resolve({sm: 'column', md: 'row'})}>
                    <Box full={true} 
                        direction={'column'} 
                        alignItems={'flexStart'} 
                        justifyContent={'center'}
                        maxWidth={viewport.resolve({sm: undefined, md: '50%'})} 
                        padding={viewport.resolve({sm: 'none', md: 'x5Right'})}
                        margin={viewport.resolve({sm: 'x8Top', md: 'none'})}>
                        <HighlightLine>
                            <HeadLine animated={animated.h1}>Contact us</HeadLine>
                        </HighlightLine>
                        <Box margin={'x4Vertical'} full={true}>
                            <address className={Styles.address}>
                                <div className={Styles.item}>
                                    <img src={Location} className={Styles.icon}/>
                                    <Body2>1001 First Avenue <br/>(At the corner of 55th Street) <br/>New York, NY  10022</Body2>
                                </div>
                                <div className={Styles.item}>
                                    <img src={Phone} className={Styles.icon}/>
                                    <Body2><ContactLink href={'tel:2128382250'}>212-838-2250</ContactLink></Body2>
                                </div>
                                <div className={Styles.item}>
                                    <img src={Email} className={Styles.icon}/>
                                    <Body2><ContactLink href={'mailto:remesoggipets@gmail.com'}>remesoggipets@gmail.com</ContactLink></Body2>
                                </div>
                            </address>
                        </Box>
                    </Box>
                    <Box full={true} maxWidth={viewport.resolve({sm: undefined, md: '50%'})}>
                        <CoverImage animated={animated.h1} src={Cover} />
                    </Box>
                </Box>
                <Box full={true} 
                    justifyContent={'flexStart'} 
                    alignItems={'stretch'}
                    direction={viewport.resolve({sm: 'column', md: 'row'})} 
                    padding={'x8Bottom'} 
                    margin={'x8Bottom'}>
                    <Box full={true} 
                        direction={'column'}
                        padding={viewport.resolve({sm: 'none', md: 'x5Right'})}
                        maxWidth={viewport.resolve({sm: undefined, md: '50%'})}
                        alignItems={viewport.resolve({sm: 'stretch', md: 'spaceBetween'})} 
                        justifyContent={'spaceBetween'}>
                        <address className={Styles.address}>
                            <Body2 margin={'x4Bottom'}><strong>Hours:</strong></Body2>
                            <Body2><strong>Monday - Friday</strong></Body2>
                            <Body3>7:30AM to 6:30PM</Body3>
                        </address>
                        <Body3 margin={'x3Top'}>Illustrations by Joan DeCollibus</Body3>
                    </Box>
                    <Box full={true} margin={viewport.resolve({sm: 'x4Vertical', md: 'none'})} maxWidth={viewport.resolve({sm: undefined, md: '50%'})}>
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3022.2275286556837!2d-73.96632098428655!3d40.75702014279653!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c258e14bd5f161%3A0xd29272b1cc6044b6!2sReme&#39;s%20Oggi%20Pets%20Inc!5e0!3m2!1sen!2sus!4v1582488197702!5m2!1sen!2sus" 
                        width="100%" 
                        height="450"
                        style={{border:0}}></iframe>
                    </Box>
                </Box>
            </Wrapper>
       </Section>
    </>
}

export default ContactUsContainer